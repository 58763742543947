<template>

  <div
    class="p-1"
    style="width: auto; height: 80vh; overflow-y: scroll;"
  >
    <!-- Subsidiary ID: {{ this.subsidiaryID }} -->
    <div
      class="mb-1 saj-title d-flex justify-content-start"
      style=""
    >
      {{ $t ("Employee Info") }}
      <!-- {{ empData.postal_code }} -->
    </div>
    <validation-observer ref="validateEmployee">
      <div
        class="row mb-2"
      >
        <div
          v-if="isUpdate"
          class="col-lg saj-text"
        >
          <div class="m-0 mt-1 mb-1">
            {{ $t ("Employee Name") }} :
          </div>
          <!-- <validation-provider
            #default="{ errors }"
            :name="$t('Employee Name')"
            rules="required"
          > -->
          <b-form-input
            v-model="empData.full_name"
            size="lg"
            class="saj-form-text"
            :placeholder="$t('Name')"
            rows="8"
            disabled
          />
          <!-- <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider> -->
        </div>
      </div>

      <b-form>
        <div
          class="row"
        >
          <div class="col-lg-4 saj-text">
            <b-form-group
              class="saj-text"
              :label="$t('First Name')"
            >
              <validation-provider
                #default="{ errors }"
                :name="$t('First Name')"
                rules="required"
              >
                <b-form-input
                  v-model="empData.first_name"
                  :placeholder="$t('Enter First Name')"
                  class="saj-form-text"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </div>
          <div class="col-lg-4 saj-text">
            <b-form-group
              class="saj-text"
              :label="$t('Middle Name')"
            >
              <b-form-input
                v-model="empData.middle_name"
                :placeholder="$t('Enter Middle Name')"
                class="saj-form-text"
              />
            </b-form-group>
          </div>
          <div class="col-lg-4 saj-text">
            <b-form-group
              class="saj-text"
              :label="$t('Last Name')"
            >
              <validation-provider
                #default="{ errors }"
                :name="$t('Last Name')"
                rules="required"
              >
                <b-form-input
                  v-model="empData.last_name"
                  :placeholder="$t('Enter Last Name')"
                  class="saj-form-text"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </div>

        </div>
      </b-form>
      <!-- </validation-observer> -->
      <div
        class="row mb-1"
      >
        <div class="col-lg-4 saj-text">
          <div class="m-0 mt-1 mb-1">
            {{ $t ("Ethnicity") }} :
          </div>
          <b-form-select
            v-model="empData.race"
            :options="isEnglish ? raceoptions_bi : raceoptions"
            :placeholder="$t('Select Ethnicity')"
            size="lg"
            class="saj-form-text"
            rows="8"
          />
        <!-- <b-form-input
          v-model="profile.ethnicity"
          :placeholder="$t('Select Ethnicity')"
          size="lg"
          class="saj-form-text"
          rows="8"
        /> -->
        </div>
        <div class="col-lg-4 saj-text">
          <div class="m-0 mt-1 mb-1">
            {{ $t ("Religion") }} :
          </div>
          <b-form-select
            v-model="empData.religion"
            :options="isEnglish ? religionoptions_bi : religionoptions"
            :placeholder="$t('Select Religion')"
            size="lg"
            class="saj-form-text"
            rows="8"
          />
        <!-- <b-form-input
          v-model="profile.religion"
          :placeholder="$t('Select Religion')"
          size="lg"
          class="saj-form-text"
          rows="8"
        /> -->
        </div>
        <div class="col-lg-4 saj-text">
          <div class="m-0 mt-1 mb-1">
            {{ $t ("Citizenship") }} :
          </div>
          <b-form-select
            v-model="empData.citizenship"
            :options="citizenshipoptions"
            :placeholder="$t('Select Citizenship')"
            size="lg"
            class="saj-form-text"
            rows="8"
          />
        <!-- <b-form-input
          v-model="profile.citizenship"
          :placeholder="$t('Select Citizenship')"
          size="lg"
          class="saj-form-text"
          rows="8"
        /> -->
        </div>
      </div>
      <div
        class="row mb-1"
      >
        <div class="col-lg-6 saj-text">
          <div class="m-0 mt-1 mb-1">
            {{ $t ("Identification Number") }} :
          </div>
          <validation-provider
            #default="{ errors }"
            :name="$t('Identification Number')"
            :rules="{required,regex: /^\d{6}\d{2}\d{4}$/}"
          >
            <b-form-input
              v-model="empData.ic"
              :placeholder="$t('Eg: xxxxxxxxxxxx')"
              type="number"
              size="lg"
              class="saj-form-text"
              rows="8"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </div>
        <div class="col-lg-6 saj-text">
          <div class="m-0 mt-1 mb-1">
            {{ $t ("Date of Birth") }} :
          </div>
          <validation-provider
            #default="{ errors }"
            :name="$t('Date of Birth')"
            rules="required"
          >
            <input
              v-model="empData.dob"
              type="date"
              style="background: #fff; height: 3.25rem;"
              class="form-control"
            >
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>

        <!-- <flat-pickr
          v-model="profile.dob"
          class="saj-form-text form-control "
          style="background: #fff; height: 3.25rem;"
          size="lg"
        /> -->
        <!-- <b-form-input
          v-model="profile.dob"
          size="lg"
          :placeholder="$t('DD/MM/YYYY')"
          class="saj-form-text"
          rows="8"
        /> -->
        </div>
      </div>
      <!-- {{ empData }} -->
      <div
        class="row mb-1"
      >
        <div class="col-lg-6 saj-text">
          <div class="m-0 mt-1 mb-1">
            {{ $t ("Gender") }} :
          </div>
          <b-form-select
            v-model="empData.gender"
            :options="isEnglish ? genderoptions_bi : genderoptions"
            :placeholder="$t('Select Gender')"
            size="lg"
            class="saj-form-text"
            rows="8"
          />
        </div>
        <div class="col-lg-6 saj-text">
          <div class="m-0 mt-1 mb-1">
            {{ $t ("Maritial Status") }} :
          </div>
          <b-form-select
            v-model="empData.marital_status"
            :options="isEnglish ? marital_statusoptions_bi : marital_statusoptions"

            :placeholder="$t('Select Status')"
            size="lg"
            class="saj-form-text"
            rows="8"
          />
        </div>
        <div class="col-lg-6 saj-text">
          <div class="m-0 mt-1 mb-1">
            {{ $t ("Address 1") }} :
          </div>
          <b-form-input
            v-model="empData.address_line1"
            size="lg"
            class="saj-form-text"
            rows="8"
          />
          <div class="m-0 mt-1 mb-1">
            {{ $t ("Address 2") }} :
          </div>
          <b-form-input
            v-model="empData.address_line2"
            size="lg"
            class="saj-form-text"
            rows="8"
          />
          <div class="m-0 mt-1 mb-1">
            {{ $t ("Address 3") }} :
          </div>
          <b-form-input
            v-model="empData.address_line3"
            size="lg"
            class="saj-form-text"
            rows="8"
          />
          <div class="m-0 mt-1 mb-1">
            {{ $t ("Postal Code") }} :
          </div>
          <b-form-input
            v-model="empData.postal_code"
            size="lg"
            class="saj-form-text"
            type="number"
            rows="8"
          />
          <div class="m-0 mt-1 mb-1">
            {{ $t ("City") }} :
          </div>
          <b-form-input
            v-model="empData.city"
            size="lg"
            class="saj-form-text"
            rows="8"
          />
        </div>
        <div class="col-lg-6 saj-text">
          <div class="m-0 mt-1 mb-1">
            {{ $t ("State") }} :
          </div>
          <b-form-input
            v-model="empData.state"
            size="lg"
            class="saj-form-text"
            rows="8"
          />
          <div class="m-0 mt-1 mb-1">
            {{ $t ("Country") }} :
          </div>
          <b-form-input
            v-model="empData.country"
            size="lg"
            class="saj-form-text"
            rows="8"
          />
          <div class="m-0 mt-1 mb-1">
            {{ $t ("Address") }} :
          </div>
          <b-form-textarea
            v-model="empData.address"
            disabled
            size="lg"
            class="saj-form-text"
            rows="8"
          />
        </div>
      </div>
      <!-- </validation-observer> -->
      <!-- sini -->
      <!-- new template work info -->
      <div
        class="row pt-1 pb-2"
        style="
                background: none;
                box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25),
                0px 0px 41px 9px rgba(0, 0, 0, 0.11);
                border-radius: 10px;
              "
      >
        <div class="col">
          <div
            class="row pl-1"
          >
            <div
              class="saj-title mb-1"
            >
              {{ $t("Work Info") }}
            </div>
          </div>
          <div
            class="row mb-1"
          >
            <div class="col-lg-6 saj-text">
              <div class="m-0 mt-1 mb-1">
                {{ $t ("Subsidiary") }} :
              </div>
              <validation-provider
                #default="{ errors }"
                :name="$t('Employee Subsidiary')"
                rules="required"
              >
                <v-select
                  v-model="empData.business_unit"
                  :placeholder="$t('Select Subsidiary')"
                  class="saj-form-text select-size-lg"
                  rows="8"
                  :options="businessUnitList"
                  :reduce="text => text.value"
                  :clearable="false"
                  label="text"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </div>
            <div class="col-lg-6 saj-text">
              <div class="m-0 mt-1 mb-1">
                {{ $t ("Position") }} :
              </div>
              <validation-provider
                #default="{ errors }"
                :name="$t('Position')"
                rules="required"
              >
                <v-select
                  v-model="empData.position"
                  :placeholder="$t('Select Position')"
                  class="saj-form-text select-size-lg"
                  rows="8"
                  :options="positionList"
                  :value="positionList"
                  :clearable="false"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </div>
            <div class="col-lg-6 saj-text">
              <div class="m-0 mt-1 mb-1">
                {{ $t ("Role(s)") }} :
              </div>
              <validation-provider
                #default="{ errors }"
                :name="$t('Role(s)')"
                rules="required"
              >
              <v-select
                v-model="userRoles"
                class="saj-title select-size-lg"
                style="background: white"
                label="text"
                :placeholder="$t('Select Role(s)')"
                multiple
                :options="allRoles"
                :selectable="(option) => !allRoles.includes(option.value)"
                :value="allRoles"
                :clearable="false"
                @input="updateRole(userRoles)"
              />
              <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </div>
            <div class="col-lg-6 saj-text" />
          </div>
          <div
            class="row mb-1"
          >
            <div class="col-lg-6 saj-text">
              <div class="m-0 mt-1 mb-1">
                {{ $t ("Department") }}:
              </div>
              <validation-provider
                #default="{ errors }"
                :name="$t('Department')"
                rules="required"
              >
                <!-- <b-form-select
                  v-model="empData.department_id"
                  :options="departmentList"
                  :value="departmentList.value"
                  :placeholder="$t('Select Department')"
                  size="lg"
                  class="saj-form-text"
                  rows="8"
                /> -->
                <v-select
                  v-model="empData.department_id"
                  :placeholder="$t('Select Department')"
                  class="saj-form-text select-size-lg"
                  rows="8"
                  :options="departmentList"
                  :reduce="text => text.value"
                  :clearable="false"
                  label="text"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </div>
            <div class="col-lg-6 saj-text">
              <div class="m-0 mt-1 mb-1">
                {{ $t ("Work Location") }} :
              </div>
              <validation-provider
                #default="{ errors }"
                :name="$t('Work Location')"
                rules="required"
              >
                <!-- <b-form-select
                  v-model="empData.branch_id"
                  :options="branchList"
                  :value="branchList.value"
                  :placeholder="$t('Select Work Location')"
                  size="lg"
                  class="saj-form-text"
                  rows="8"
                /> -->
                <!-- {{ empData.branch_id }} -->
                <v-select
                  v-model="empData.branch_id"
                  :placeholder="$t('Select Work Location')"
                  class="saj-form-text select-size-lg"
                  rows="8"
                  :options="branchList"
                  :reduce="text => text.value"
                  :clearable="false"
                  label="text"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </div>
          </div>
          <div
            class="row mb-1"
          >
            <div class="col-lg-6 saj-text">
              <div class="m-0 mt-1 mb-1">
                {{ $t ("Grade") }} :
              </div>
              <validation-provider
                #default="{ errors }"
                :name="$t('Grade')"
                rules="required"
              >
                <v-select
                  v-model="empData.grade"
                  :placeholder="$t('Select Grade')"
                  class="saj-form-text select-size-lg"
                  rows="8"
                  :options="gradeList"
                  :clearable="false"
                  label="text"
                  @input="getGradeSet(), getAllRoles()"
                />
                <!-- <b-form-select
                  v-model="empData.grade"
                  :options="gradeList"
                  :value="gradeList"
                  :placeholder="$t('Select Grade')"
                  size="lg"
                  class="saj-form-text"
                  rows="8"
                  @change="getGradeSet(), getAllRoles()"
                /> -->
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </div>
            <div class="col-lg-6 saj-text">
              <div class="m-0 mt-1 mb-1">
                {{ $t ("Grade set") }} :
              </div>
              <b-form-input
                v-model="profile.grade_set"
                :placeholder="$t('Select Grade Set')"
                :disabled="empData.grade === null || empData.grade === '' "
                size="lg"
                class="saj-form-text"
                :readonly="true"
                rows="8"
              />
            </div>
          </div>
          <div
            class="row mb-1"
          >
            <div class="col-lg-6 saj-text">
              <div class="m-0 mt-1 mb-1">
                {{ $t ("Employee Number") }} :
              </div>
              <validation-provider
                #default="{ errors }"
                :name="$t('Employee Number')"
                rules="required"
              >
                <b-form-input
                  v-model="empData.employee_number"
                  :placeholder="$t('Enter Employee Number')"
                  size="lg"
                  class="saj-form-text"
                  rows="8"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </div>
            <div class="col-lg-6 saj-text">
              <div class="m-0 mt-1 mb-1">
                {{ $t ("Date of Joined") }} :
              </div>
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  :name="$t('Date of Joined')"
                  rules="required"
                >
                  <!-- <flat-pickr
                    v-model="empData.hire_date"
                    class="saj-form-text form-control dateJoined"
                    style="background: #fff; height: 3.25rem;"
                    :config="{ dateFormat: 'Y-m-d' , altInput: true, altFormat:'d-m-Y'}"
                    size="lg"
                  /> -->
                  <input
                    v-model="empData.hire_date"
                    type="date"
                    style="background: #fff; height: 3.25rem;"
                    class="form-control"
                  >
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            <!-- <b-form-input
                      v-model="profile.doj"
                      :placeholder="$t('DD/MM/YYYY')"
                      size="lg"
                      class="saj-form-text"
                      rows="8"
                    /> -->
            </div>
          </div>
          <div
            class="row mb-1"
          >
            <div class="col-lg-6 saj-text">
              <div class="m-0 mt-1 mb-1">
                {{ $t ("Email") }} :
              </div>
              <validation-provider
                #default="{ errors }"
                :name="$t('Email')"
                rules="required|email"
              >
                <b-form-input
                  v-model="empData.email"
                  :placeholder="$t('Enter Email')"
                  size="lg"
                  class="saj-form-text"
                  rows="8"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </div>
            <div class="col-lg-6 saj-text">
              <div class="m-0 mt-1 mb-1">
                {{ $t ("Profile Picture") }} :
              </div>
              <b-form-file
                v-model="profile.profile_picture"
                type="file"
                class="saj-text"
                size="lg"
                :browse-text="$t('Choose File')"
                :placeholder="$t('No file choosen')"
                @change="onFileChange"
              />
            <!-- <b-form-input
                      v-model="profile.profile_picture"
                      :placeholder="$t('Insert Your Profile Picture')"
                      size="lg"
                      class="saj-form-text"
                      rows="8"
                    /> -->
            </div>
          </div>
        </div>
      </div>
      <div
        class="row pt-1 pb-2"
        style="
                background: none;
                box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25),
                0px 0px 41px 9px rgba(0, 0, 0, 0.11);
                border-radius: 10px;
              "
      >
        <div class="col">
          <div
            class="row pl-1"
          >
            <div
              class="saj-title mb-1"
            >
              {{ $t("Supervisor Info") }}
            </div>
          </div>
          <div
            class="row mb-1"
          >
            <!-- <div class="col-lg-6 saj-text" /> -->
            <div class="col-lg-6 saj-text">
              <div class="m-0 mt-1 mb-1">
                {{ $t ("Subsidiary") }} :
              </div>
              <validation-provider
                #default="{ errors }"
                :name="$t('Subsidiary')"
                rules="required"
              >
                <v-select
                  v-model="selectedSub"
                  :placeholder="$t('Select Subsidiary')"
                  class="saj-form-text select-size-lg"
                  rows="8"
                  :options="subList"
                  :reduce="text => text.value"
                  :clearable="false"
                  label="text"
                  @input="getSvName('update')"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </div>
            <div class="col-lg-6 saj-text">
              <div class="m-0 mt-1 mb-1">
                {{ $t ("Supervisor Id") }} :
              </div>
               <validation-provider
                #default="{ errors }"
                :name="$t('Supervisor Id')"
                rules="required"
              >
              <v-select
                v-model="empData.sv_employee_number"
                :placeholder="$t('Supervisor Id')"
                class="saj-form-text select-size-lg"
                rows="8"
                :options="svNameList"
                :clearable="false"
                label="empNo"
                @input="showValue(empData.sv_employee_number)"
              />
              <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </div>
            <div class="col-lg-6 saj-text">
              <div class="m-0 mt-1 mb-1">
                {{ $t ("Supervisor Name") }} :
              </div>

              <b-form-input
                v-model="supervisorName"
                size="lg"
                disabled
                class="saj-text m-0"
                :placeholder="$t('Supervisor Name')"
              />
            </div>
          </div>
        </div>
      </div>
      <div
        class="d-flex justify-content-end"
        style=""
      >
        <button
          class="btn btn-close m-1 saj-button"
          aria-label="Close modal"
          style="

                  color: white;
                  background: #ff0000;
                "
          @click="close()"
        >
          {{ $t('Back') }}
        </button>
        <button
          class="btn m-1 saj-button"
          aria-label="submit modal"
          style="

                  color: white;
                  background: #0b103c !important;
                  border-color: #0b103c !important;
                "
          @click="isUpdate ? updateEmployee() : addEmployee()"
        >
          {{ $t('Save') }}
        </button>
      </div>
      <!-- </validation-observer> -->
    </validation-observer></div>

</template>
<script>
import {
  BForm,
  BFormInput,
  BFormGroup,
  BFormSelect,
  BFormFile,
  BFormTextarea,
} from "bootstrap-vue"
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
// import flatPickr from "vue-flatpickr-component"
import vSelect from 'vue-select'
import SAJToast from '@/component/saj-toastification.vue'

export default {
  components: {
    // BButton,
    // BModal,
    BForm,
    BFormInput,
    BFormGroup,
    BFormTextarea,
    // BInputGroup,
    // BFormGroupAppend,
    // BCard,
    // BFormTimepicker,
    // BCardCode,
    // BRow,
    // BCol,
    // BCalendar,
    // BCardText,
    ValidationProvider,
    ValidationObserver,
    BFormSelect,
    // flatPickr,
    BFormFile,
    vSelect,
  },
  props: {
    empInfo: {
      type: Object,
      default: () => {},
    },
    isUpdate: {
      type: Boolean,
      default: false,
    },
    subsID: {
      type: null,
      default: null,
    },

  },
  data() {
    return {
      empData: this.isUpdate ? this.empInfo : {
        race: null,
        religion: null,
        citizenship: null,
        gender: null,
        marital_status: null,
        grade: null,
        middle_name: "",
        business_unit: []

      },
      supervisor: [],
      supervisorName: null,
      // name: null,
      // details: null,
      required,
      fullName: '',
      userRoles: [],
      subsidiaryID: null,
      businessUnitList: [],
      allbusinessUnit: [],
      departmentList: [],
      alldepartment: [],
      allBranch: [],
      allRoles: [],
      branchList: [],
      positionList: [],
      allPosition: [],
      gradeList: [],
      allGrade: [],
      subsidiaryStaffList: [],
      profile: {
        employee_name: this.empData !== undefined ? this.empData.full_name : "",
        first_name: this.empData !== undefined ? this.empData.first_name : "",
        middle_name: "",
        last_name: "",
        ethnicity: "",
        religion: "",
        citizenship: "",
        ic: "",
        dob: "",
        gender: "",
        marital_status: "",
        business_unit: "",
        position: "",
        department: "",
        work_location: "",
        grade: "",
        grade_set: "",
        employee_number: "",
        doj: "",
        email: "",
        profile_picture: [],
      },
      genderoptions_bi: [
        { value: null, text: "Choose Gender", disabled: true },
        { value: "Male", text: "Male" },
        { value: "Female", text: "Female" },
      ],
      genderoptions: [
        { value: null, text: "Pilih Jantina", disabled: true },
        { value: "Male", text: "Lelaki" },
        { value: "Female", text: "Perempuan" },
      ],
      marital_statusoptions_bi: [
        // { value: null, text: "Choose Marital status", disabled: true },
        { value: null, text: "Choose Marital status", disabled: true },
        { value: "Single", text: "Single" },
        { value: "Married", text: "Married" },
        { value: "Divorced", text: "Divorced" },
        { value: "Widowed", text: "Widowed" },
      ],
      marital_statusoptions: [
        { value: null, text: "Pilih Status Perkahwinan", disabled: true },
        { value: "Single", text: "Bujang" },
        { value: "Married", text: "Berkahwin" },
        { value: "Divorced", text: "Bercerai" },
        { value: "Widowed", text: "Balu/Janda/Duda" },
      ],
      raceoptions_bi: [
        { value: null, text: "Choose Ethnicity", disabled: true },
        { value: "Malay", text: "Malay" },
        { value: "Chinese", text: "Chinese" },
        { value: "Indian", text: "Indian" },
        { value: "Others", text: "Others" },
      ],
      raceoptions: [
        { value: null, text: "Pilih Kaum", disabled: true },
        { value: "Malay", text: "Melayu" },
        { value: "Chinese", text: "Cina" },
        { value: "Indian", text: "India" },
        { value: "Others", text: "Lain-lain" },
      ],
      religionoptions_bi: [
        { value: null, text: "Choose Religion status", disabled: true },
        { value: "Islam", text: "Islam" },
        { value: "Hinduism", text: "Hinduism" },
        { value: "Buddhism", text: "Buddhaism" },
        { value: "Christianity", text: "Christianity" },
        { value: "Others", text: "Others" },
      ],
      religionoptions: [
        { value: null, text: "Pilih Agama", disabled: true },
        { value: "Islam", text: "Islam" },
        { value: "Hinduism", text: "Hinduism" },
        { value: "Buddhism", text: "Buddhism" },
        { value: "Christianity", text: "Kristianiti" },
        { value: "Others", text: "Lain-lain" },
      ],
      citizenshipoptions: [
        { value: null, text: `${this.$i18n.t("Choose citizenship")}`, disabled: true },
        { value: "Malaysia", text: "Malaysia" },
        { value: "Others", text: "Others" },
      ],
      svNameList: [],
      supervisorListName: [],
      subList: [],
      selectedSub: null,
    }
  },
  computed: {
    isEnglish() {
      return this.$i18n.locale === 'en'
    },
  },
  beforeMount() {
    this.id = this.$route.params.id
  },
  mounted() {
    this.subsidiaryID = this.subsID
    this.getSubsidiary()
    this.getDepartment()
    this.getWorkLocation()
    this.getPosition()
    this.getGrade()
    this.getAllRoles()
    // this.getGradeSet()
    if (this.isUpdate){
      this.getUserRole()
      this.getGradeSet()
      this.showValue(this.empData.sv_employee_number !== null ? this.empData.sv_employee_number : 'Please choose Supervisor')
      // this.showValue(this.empData.sv_employee_number === null ? 'Please choose Supervisor' : this.empData.sv_employee_number)
      // this.getSvName()
    }
    this.getSubsidiaryList()
    // console.log('ini', this.empInfo)
    // console.log('sini', this.empData.sv_employee_number)
  },
  methods: {
    onFileChange(e) {
      // console.log('on file change', e.target.files[0].size)
      if (e.target.files.length !== 0){
        const fileSize = e.target.files[0].size / 1000

        if (fileSize > 5120){
          this.$toast(
            {
              component: SAJToast,
              props: {
                title: `${this.$t('File must less than 5 MB')}.`,
                icon: "XIcon",
                variant: "success",
                titleColor: "#000",
                iconBg: '#e80202',
              },
            },
            {
              position: "top-right",
              type: 'info',
            },
          )
        }
      }
    },
    close() {
      this.$emit('close')
    },
    showValue(a) {
      // console.log("Show employee number:", a)
      this.$axios.get(`${this.$baseUrl}/employees/getAllEmployeesNameWithoutPerformance?employee_number=${a.empNo !== undefined ? a.empNo : a}`).then(response => {
        const SVname = response.data.data
        this.supervisorName = SVname[0].full_name !== undefined ? SVname[0].full_name : 'Please choose Supervisor'
        if (SVname[0].full_name !== undefined) {
          this.selectedSub = SVname[0].business_unit
        }
        if (SVname[0].full_name !== undefined){
          this.getSvName()
        }
        // console.log("Full Name:", SVname)
      }).catch(() => {
        // console.log("sini", error)
        this.supervisorName = 'Please choose Supervisor'
      })
    },
    getSvName(a){
      // console.log('sv name')
      this.$axios.get(`${this.$baseUrl}/employees/getAllEmployeesNameWithoutPerformance?subsidiary_id=${this.selectedSub}&status=active`).then(response => {
        // console.log(response)
        this.supervisorListName = response.data.data
        if (a === 'update'){
          this.supervisorName = 'Please choose Supervisor'
          this.empData.sv_employee_number = ""
        }
        this.svNameList = this.supervisorListName.map(x => ({
          empNo: x.employee_number,
        }))
      })
    },
    getAllRoles(){
      this.allRoles = []
      const params = new URLSearchParams()

      // params.append('toAssign', 1)
      this.$axios.get(`${this.$baseUrl}/roles/getAllRoles`, { params }).then(res => {
        // console.log('all user', res)
        const roles = res.data.data

        roles.forEach(k => {
          this.allRoles.push({
            text: k.name,
            value: k.id,
          })
        })
      })
    },
    getUserRole(){
      this.$axios.get(`${this.$baseUrl}/roles/getUserRole?user_id=${this.empData.user_id}`).then(res => {
        const roles = res.data.data.role
        roles.forEach(k => {
          this.userRoles.push({
            text: k.role_name,
            value: k.role_id,
          })
        })
      })
    },
    updateRole(){
      // console.log('selected role', selectedRole)
    },
    getSubsidiary(){
      this.$axios.get(`${this.$baseUrl}/subsidiary/getById?id=${this.subsidiaryID}`).then(response => {
        this.allbusinessUnit = response.data.data
        this.allbusinessUnit.forEach(b => {
          this.businessUnitList.push({
            text: b.subsidiary_name,
            value: b.id,

          })
        })
      })
    },
    getSubsidiaryList(){
      this.$axios.get(`${this.$baseUrl}/subsidiary/getAll`).then(response => {
        const list = response.data.data.subsidiaries
        this.subList = list.map(x => ({
          text: x.subsidiary_name,
          value: x.id,
        }))
      })
    },
    getDepartment(){
      this.$axios.get(`${this.$baseUrl}/departments/get_departments_by_subsidiary?subsidiary_id=${this.subsidiaryID}`).then(response => {
        const result = response.data
        if (result.success){
          this.alldepartment = response.data.data

          this.alldepartment.forEach(d => {
            this.departmentList.push({
              text: d.name,
              value: d.id,
            })
          })
        }
      })
    },
    getWorkLocation(){
      this.$axios.get(`${this.$baseUrl}/branch/get_branch_by_company?company_id=${this.subsidiaryID}`).then(response => {
        this.allBranch = response.data.data
        this.allBranch.forEach(d => {
          this.branchList.push({
            text: d.name,
            value: d.id,
          })
        })
      })
    },
    getPosition(){
      this.$axios.get(`${this.$baseUrl}/position/get_position_all`).then(response => {
        this.allPosition = response.data.data

        this.allPosition.forEach(d => {
          this.positionList.push(d.name)
        })
      })
    },
    getGrade(){
      this.$axios.get(`${this.$baseUrl}/grades/`).then(response => {
        this.allGrade = response.data.data
        this.gradeList = this.allGrade.map(d => (d.grade))
      })
    },
    getGradeSet(){
      if (this.empData.grade !== null){
        this.$axios.get(`${this.$baseUrl}/grades/get_grade_set?grade=${this.empData.grade}`).then(response => {
          this.profile.grade_set = response.data.data.grade_set
        })
      } else {
        this.profile.grade_set = null
      }
    },
    addEmployee(){
      this.$refs.validateEmployee.validate().then(success => {
        if (success){
          // this.addUserRole()
          const data = new FormData()
          this.fullName = `${this.empData.first_name} ${this.empData.middle_name} ${this.empData.last_name}`
          data.append('business_unit', this.empData.business_unit)
          data.append('employee_number', this.empData.employee_number)
          data.append('full_name', this.fullName)
          data.append('first_name', this.empData.first_name)
          data.append('middle_name', this.empData.middle_name)
          data.append('last_name', this.empData.last_name)
          data.append('email', this.empData.email)
          data.append('ic', this.empData.ic)
          data.append('department_id', this.empData.department_id)
          data.append('branch_id', this.empData.branch_id)
          data.append('grade', this.empData.grade)
          data.append('position', this.empData.position)
          data.append('race', this.empData.race)
          data.append('religion', this.empData.religion)
          data.append('citizenship', this.empData.citizenship)
          data.append('dob', this.empData.dob)
          data.append('gender', this.empData.gender)
          data.append('marital_status', this.empData.marital_status)
          data.append('hire_date', this.empData.hire_date)
          data.append('file', this.profile.profile_picture)
          data.append('supervisor_number', this.empData.sv_employee_number === undefined ? "" : this.empData.sv_employee_number.empNo)
          data.append('address_line1', this.empData.address_line1 === undefined ? "" : this.empData.address_line1)
          data.append('address_line2', this.empData.address_line2 === undefined ? "" : this.empData.address_line2)
          data.append('address_line3', this.empData.address_line3 === undefined ? "" : this.empData.address_line3)
          data.append('postal_code', this.empData.postal_code === undefined ? "" : this.empData.postal_code)
          data.append('city', this.empData.city === undefined ? "" : this.empData.city)
          data.append('state', this.empData.state === undefined ? "" : this.empData.state)
          data.append('country', this.empData.country === undefined ? "" : this.empData.country)
          // console.log('empdata', this.empData)
          // data.forEach(a => {
          //   console.log("append", a)
          // })
          this.$axios.post(`${this.$baseUrl}/employees/register_employee`, data)
            .then(res => {
              this.$emit('close')
              this.$emit('add-employee')
              // console.log('response', res)
              this.addUserRole(res.data.data.user_id)
              // data.forEach(a => {
              //   console.log("append", a)
              // })
              setTimeout(() => {
                this.$toast(
                  {
                    component: SAJToast,
                    props: {
                      title: `${this.$t('Employee successfully added')}.`,
                      icon: 'CheckCircleIcon',
                      variant: 'success',
                      titleColor: '#000',
                    },
                  },
                  {
                    position: "top-right",
                    type: 'info',
                  },
                )
                // this.$emit('add-employee')
              }, 1000)
            }).catch(err => {
              // console.log('err', err.response.data.data.email)
              this.$toast(
                {
                  component: SAJToast,
                  props: {
                    title: `${err.response.data.data.email !== undefined ? `${err.response.data.data.email}` : ''}  ${err.response.data.data.employee_number !== undefined ? err.response.data.data.employee_number : ''}`,
                    icon: "AlertTriangleIcon",
                    iconColor: 'white',
                    iconBg: '#FF000F',
                    titleColor: "#000",
                  },
                },
                {
                  position: "top-right",
                  type: 'error',
                },
              )
            })

          //   error?.response?.status === 404) {
          //   console.log('errorrrrrr', error)
          //       this.$toast(
          //           {
          //             component: DToastification,
          //             props: {
          //               hideClose: true,
          //               title: Info,
          //               text: 'Wrong email or password!',
          //               icon: "AlertCircleIcon",
          //               variant: "warning300",
          //               iconColor: "warning300",
          //               iconBg: '#FFA346',
          //               titleColor: '#FFFFFF',
          //             },
          //           },
          //           {
          //             position: "top-right",
          //             toastClassName: "",
          //           },
          //         )
          // }
          //   break
        }
      })
    },
    addUserRole(a){
      const data = new FormData()
      data.append('user_id', a)
      this.userRoles.forEach(item => {
        data.append('role_id[]', item.value)
      })

      this.$axios.post(`${this.$baseUrl}/roles/updateUserRole`, data)
        .then(() => {
        })
    },
    updateUserRole(){
      const data = new FormData()
      data.append('user_id', this.empData.user_id)
      this.userRoles.forEach(item => {
        data.append('role_id[]', item.value)
      })

      this.$axios.post(`${this.$baseUrl}/roles/updateUserRole`, data)
        .then(() => {
          this.$emit('close')
          this.$emit('update-employee')
          // console.log('update', response)
        }).catch(err => {
          this.$emit('close')
          this.$emit('update-employee')
          this.$toast(
            {
              component: SAJToast,
              props: {
                title: `${this.$t('Roles failed to update')}.`,
                text: err.response.data.message,
                icon: 'XIcon',
                iconBg: '#e80202',
                titleColor: '#000',
              },
            },
            {
              position: "top-right",
              type: 'error',
            },
          )
          // console.log('update', err.response)
        })
    },
    updateEmployee(){
      this.$refs.validateEmployee.validate().then(success => {
        if (success){
          // console.log('emp data update', this.empData)
          const data = new FormData()
          this.fullName = `${this.empData.first_name} ${this.empData.middle_name === null ? '' : this.empData.middle_name} ${this.empData.last_name}`
          // data.append('assign', 1)
          data.append('user_id', this.empData.user_id)
          data.append('business_unit', this.empData.business_unit)
          data.append('employee_number', this.empData.employee_number)
          data.append('full_name', this.fullName)
          data.append('first_name', this.empData.first_name)
          data.append('middle_name', this.empData.middle_name === null || this.empData.middle_name === "" ? "" : this.empData.middle_name)
          data.append('last_name', this.empData.last_name)
          data.append('email', this.empData.email)
          data.append('ic', this.empData.ic)
          data.append('department_id', this.empData.department_id)
          data.append('branch_id', this.empData.branch_id)
          data.append('grade', this.empData.grade)
          data.append('position', this.empData.position)
          data.append('race', this.empData.race)
          data.append('religion', this.empData.religion)
          data.append('citizenship', this.empData.citizenship)
          data.append('dob', this.empData.dob)
          data.append('gender', this.empData.gender)
          data.append('marital_status', this.empData.marital_status)
          data.append('hire_date', this.empData.hire_date)
          data.append('file', this.profile.profile_picture)
          data.append('address_line1', this.empData.address_line1 === undefined || this.empData.address_line1 === null ? "" : this.empData.address_line1)
          data.append('address_line2', this.empData.address_line2 === undefined || this.empData.address_line2 === null ? "" : this.empData.address_line2)
          data.append('address_line3', this.empData.address_line3 === undefined || this.empData.address_line3 === null ? "" : this.empData.address_line3)
          data.append('postal_code', this.empData.postal_code === undefined || this.empData.postal_code === null ? "" : this.empData.postal_code)
          data.append('city', this.empData.city === undefined || this.empData.city === null ? "" : this.empData.city)
          data.append('state', this.empData.state === undefined || this.empData.state === null ? "" : this.empData.state)
          data.append('country', this.empData.country === undefined || this.empData.country === null ? "" : this.empData.country)
          if (this.empData.sv_employee_number !== null) {
            data.append('supervisor_number', this.empData.sv_employee_number.empNo !== undefined ? this.empData.sv_employee_number.empNo : this.empData.sv_employee_number)
          }
          // data.forEach(a => {
          //   console.log('append', a)
          // })
          // eslint-disable-next-line no-restricted-syntax
          // for (const pair of data.entries()) {
          //   console.log(`${pair[0]}, ${pair[1]}`)
          // }

          this.$axios.post(`${this.$baseUrl}/employees/updateEmployee`, data)
            .then(() => {
              // this.$emit('close')
              // this.$emit('update-employee')
              // data.forEach(a => {
              //   console.log("append", a)
              // })
              this.updateUserRole()
              setTimeout(() => {
                this.$toast(
                  {
                    component: SAJToast,
                    props: {
                      title: `${this.$t('Employee info successfully updated')}.`,
                      icon: 'CheckCircleIcon',
                      variant: 'success',
                      titleColor: '#000',
                    },
                  },
                  {
                    position: "top-right",
                    type: 'info',
                  },
                )
              }, 1000)
            })
        //   break
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>

<style>
  .dateJoined .flatpickr-input, .flatpickr-input ~ .form-control, .flatpickr-human-friendly {
  height: 53px !important
}
</style>

<!-- .flatpickr-input, .flatpickr-input ~ .form-control, .flatpickr-human-friendly -->
